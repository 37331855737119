@import 'styles/variable';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 64px);
  padding-right: 20px;
  padding-left: 20px;
  background-color: $white-2;

  img {
    margin-top: 80px;
    margin-bottom: 24px;

    @include sm {
      max-width: 330px
    }
  }

  .title {
    margin-bottom: 24px;
    text-align: center;
  }

  .subTitle {
    margin-bottom: 24px;
    text-align: center;
  }

  button {
    margin-bottom: 120px;

    @include sm {
      height: 40px;
    }
  }
}