@import '../styles/mixins.scss';
/*******************************************/
/*                INITIAL                  */
/*******************************************/

$NextAppMaxWidth: 1136px;

$minWidth: 1024px;
$sideWidth: 205px;

/*******************************************/
/*               BOX SHADOW                */
/*******************************************/

$box-shadow-1: 0px 3px 6px rgba(149, 150, 150, 0.6);
$box-shadow-2: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
$box-shadow-8: 3px 6px 15px 0 rgba(35, 121, 234, 0.13);

/*******************************************/
/*           BACKGROUND COLORS             */
/*******************************************/
$bg-lightgrey: rgba(188, 188, 188, 0.1);

/*******************************************/
/*                 COLORS                  */
/*******************************************/

// $primaryBlue: #2379ea;
$primaryBlue-3: #136fd3;
$primaryBlue: #2379ea;
$darkBlue: #163f72;
$darkBlue-1: #0052cc;
$lightgrey: #bcbcbc;
$lightgrey-2: #e2e2e2;
$lightgrey-3: #f0f0f0;
$darkgrey: #707070;
// background
$white-1: #f9f9f9;
// border
$white-2: #f0f0f0;
// standard white
$white-3: #ffffff;
$overlay: #353535;
$dividerLine: #dedede;
$red: #ff4747;
$black: #353535;
$pink: #ff3a99;
$pink-1: #f50057;
$cyan: #00bad3;

/*******************************************/
/*                 SIZES                  */
/*******************************************/

$desktop-font-size-xsm: 11px;
$desktop-font-size-sm: 12px;
$desktop-font-size-base: 14px;
$desktop-font-size-lg: 16px;
$desktop-font-size-xl: 18px;
$desktop-font-size-xxl: 21px;
$desktop-font-size-xxxl: 27px;

$mobile-font-size-xsm: 11px;
$mobile-font-size-sm: 12px;
$mobile-font-size-base: 14px;
$mobile-font-size-lg: 16px;
$mobile-font-size-xl: 18px;
$mobile-font-size-xxl: 21px;
$mobile-font-size-xxxl: 21px;
$maxContentWidth: 1136px;

/*******************************************/
/*                 Custom                  */
/*******************************************/
.GlobalContainer {
  max-width: $maxContentWidth;
  width: 100%;
  margin: 0 auto;
  // padding-left: 24px !important;
  // padding-right: 24px !important;

  @media (max-width: 799px) {
    padding-left: 24px !important;
    padding-right: 24px !important;
    max-width: 688px;
  }

  @media (max-width: 360px) {
    padding-left: 24px !important;
    padding-right: 24px !important;
    max-width: 320px;
  }
}

// Next app Default page style
.GlobalNextAppContainer {
  max-width: $maxContentWidth;
  width: 100%;
  margin: 0 auto;

  // padding-left: 10px;
  // padding-right: 10px;
  :global {
    .MuiOutlinedInput-notchedOutline {
      border-width: 0;
    }

    .MuiOutlinedInput-input {
      font-size: 14px;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
    }
  }

  @include msd {
    max-width: 688px;
  }

  // @include xsm {
  //   max-width: 320px;
  // }

  @include sm {
    padding: 0px 10px;
  }
}